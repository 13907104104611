<template>
    <div>
      <b-modal
        id="modal-detail-kegiatan-kelompok"
        size="lg"
        centered
        title="Detail Kegiatan Kelompok"
        header-bg-variant="primary"
        header-text-variant="light"
        @show="openModal()"
      >
        <b-row class="m-0">
          <b-col cols="12">
            <h6
              style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
              Kegiatan Kelompok
            </h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="edu_id">Edu ID</label>
            <h6 class=""><strong>{{is_data.edu_id || '-'}}</strong></h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="kode_club">Kode Club</label>
            <h6 class=""><strong>{{is_data.kode_club || '-'}}</strong></h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="tanggal_pelayanan">Tanggal Pelayanan</label>
            <h6 class=""><strong>{{$moment(is_data.tanggal_pelayanan).format('LL')}}</strong></h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="lokasi">Lokasi</label>
            <h6 class=""><strong>{{is_data.lokasi || '-'}}</strong></h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="kode_kegiatan">Kegiatan</label>
            <h6 class=""><strong>{{is_data.kode_kegiatan || '-'}}</strong></h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="pembicara">Pembicara</label>
            <h6 class=""><strong>{{is_data.pembicara || '-'}}</strong></h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="kode_kelompok">Kelompok</label>
            <h6 class=""><strong>{{is_data.kode_kelompok || '-'}}</strong></h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="materi">Materi</label>
            <h6 class=""><strong>{{is_data.materi || '-'}}</strong></h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="keterangan">Keterangan</label>
            <h6 class=""><strong>{{is_data.keterangan || '-'}}</strong></h6>
          </b-col>
          <b-col cols="6" class="">
            <label for="biaya">Biaya</label>
            <h6 class=""><strong>{{$numberFormat(is_data.biaya, 'idr')}}</strong></h6>
          </b-col>
        </b-row>
        <b-row class="m-0 mt-3">
          <b-col cols="12">
            <hr>
            <h6
              style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
              Peserta
            </h6>
          </b-col>
          <b-col v-for="(item, key) of listPeserta" :key="key" cols="6" class="">
            <div class="card p-3 mt-1">
              <h5>{{item.nama_lengkap}}</h5>
              <b-row class="m-0">
                <b-col cols="5">No BPJS</b-col>
                <b-col cols="7">: {{item.no_bpjs}}</b-col>
                <b-col cols="5">Jenis Kelamin</b-col>
                <b-col cols="7">: {{item.jenis_kelamin == 'P' ? 'Perempuan' : 'Laki - Laki'}}</b-col>
                <b-col cols="5">Tanggal Lahir</b-col>
                <b-col cols="7">: {{$moment(item.tanggal_lahir).format('LL')}}</b-col>
                <b-col cols="5">Diabetes</b-col>
                <b-col cols="7">: {{item.pst_prb || '-'}}</b-col>
                <b-col cols="5">Hipertensi</b-col>
                <b-col cols="7">: {{item.pst_prol || '-'}}</b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-detail-kegiatan-kelompok')">
            Close
          </b-button>
        </template>
      </b-modal>
    </div>
</template>
  
<script>
  export default {
    name: "modalInput",
    props: ['is_data'],
    data() {
      return {
        listPeserta: [],
        busy: false,
        button: "Simpan",
      };
    },
    computed: {
      tanggal_sesi(){
        return this.$store.state.tanggal_sesi
      },
      formString() {
        return JSON.stringify(this.data, null, 4);
      },
      isValid() {
        return !this.$v.data.$invalid;
      },
      isDirty() {
        return this.$v.data.$anyDirty;
      },
    },
    methods: {
      openModal(){
        const vm = this
        vm.getDatas()
      },
      async getDatas(){
        const vm = this
        vm.busy = true
        try {
          // const items = await vm.$axios.post('/kegiatan_kelompok/list')
          const listPeserta = await vm.$axios.post('/peserta_kegiatan_kelompok/list', {kegiatan_kelompok_id: vm.is_data.kegiatan_kelompok_id})
          // console.log('peserta_kegiatan_kelompok/list', listPeserta)
          if(listPeserta.data.status == 200){
            vm.listPeserta = listPeserta.data.status == 200 ? listPeserta.data.data.map((x, i) => { return {
              ...x,
              no: i + 1,
              nama_jenis_kelamin: vm.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan',
              nama_tanggal_lahir: vm.$moment(vm.tanggal_lahir).format('LL'),
            }}) : []
          }
        } catch (err) {
          console.log(err)
          if (err.message) {
            vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
          } else {
            vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
          }
        } finally {
          vm.busy = false;
        }
      },
    },
  };
  </script>
  