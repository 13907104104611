<template>
    <div>
      <b-modal
        id="modal-input-kegiatan-kelompok"
        size="lg"
        centered
        title="Tambah Data Kegiatan Kelompok"
        header-bg-variant="primary"
        header-text-variant="light"
        @show="openModal()"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Club <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('kode_club')"
              type="text"
              v-model="$v.data.kode_club.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal Pelayanan <span class="text-danger">*</span>
            </template>
            <date-picker
              style="width: 100%"
              id="tanggal"
              format="dddd, DD MMMM YYYY"
              v-model="$v.data.tanggal_pelayanan.$model"
            ></date-picker>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Kegiatan <span class="text-danger">*</span>
            </template>
            <multiselect
              id="kd_tkp"
              v-model="$v.data.kode_kegiatan.$model"
              :options="$store.state.data_static.kegiatan"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              placeholder="-- Pilih Kegiatan --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Kelompok <span class="text-danger">*</span>
            </template>
            <multiselect
              id="kd_tkp"
              v-model="$v.data.kode_kelompok.$model"
              :options="$store.state.data_static.kelompok"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              placeholder="-- Pilih Kelompok --"
              size="sm"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Materi <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('materi')"
              type="text"
              v-model="$v.data.materi.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Pembicaraan <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('pembicara')"
              type="text"
              v-model="$v.data.pembicara.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Lokasi <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('lokasi')"
              type="text"
              v-model="$v.data.lokasi.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Keterangan <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              :state="checkIfValid('keterangan')"
              v-model="$v.data.keterangan.$model"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Biaya <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('biaya')"
              type="number"
              v-model="$v.data.biaya.$model"
            ></b-form-input>
          </b-form-group>
        </b-form>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-input-kegiatan-kelompok')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
            >{{ button }}</b-button
          >
        </template>
      </b-modal>
    </div>
</template>
  
<script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  export default {
    name: "modalInput",
    data() {
      return {
        data: {
          // edu_id: null,
          kode_club: null,
          tanggal_pelayanan: null,
          kode_kegiatan: null,
          kode_kelompok: null,
          materi: null,
          pembicara: null,
          lokasi: null,
          keterangan: null,
          biaya: null,
        },
        busy: false,
        button: "Simpan",
      };
    },
    computed: {
      tanggal_sesi(){
        return this.$store.state.tanggal_sesi
      },
      formString() {
        return JSON.stringify(this.data, null, 4);
      },
      isValid() {
        return !this.$v.data.$invalid;
      },
      isDirty() {
        return this.$v.data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      data: {
        // edu_id: {  },
        kode_club: { required },
        tanggal_pelayanan: { required },
        kode_kegiatan: { required },
        kode_kelompok: { required },
        materi: { required },
        pembicara: { required },
        lokasi: { required },
        keterangan: { required },
        biaya: { required },
      },
    },
    methods: {
      openModal(){
        const vm = this
        vm.reset()
      },
      async simpan(){
        const vm = this
        vm.busy = true
        try {
          await this.$v.data.$touch();
          if (vm.isValid && vm.isDirty) {
            let res = await vm.$axios.post('/kegiatan_kelompok/register', {
              ...vm.data,
              kode_kegiatan: vm.data.kode_kegiatan.value,
              kode_kelompok: vm.data.kode_kelompok.value,
            })
            // console.log('register kegiatan_kelompok', res)
            if(res.data.status == 200){
              vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Membuat Kegiatan Kelompok', showing: true });
              vm.$bvModal.hide('modal-input-kegiatan-kelompok')
              vm.reset()
            }else{
              vm.$emit("alertFromChild", { variant: "danger", msg: res.data.message, showing: true });
            }
          }else{
            vm.$emit("alertFromChild", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
          }
        } catch (error) {
          console.log(error)
          if (error.message) {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
          } else {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
          }
        } finally {
          vm.busy = false
        }
      },
      reset() {
        const vm = this
        vm.$v.$reset();
        vm.data = {
          // edu_id: null,
          kode_club: null,
          tanggal_pelayanan: vm.tanggal_sesi,
          kode_kegiatan: null,
          kode_kelompok: null,
          materi: null,
          pembicara: null,
          lokasi: null,
          keterangan: null,
          biaya: null,
        };
      },
      checkIfValid(fieldName) {
        const field = this.$v.data[fieldName];
        // console.log('fieldName', fieldName)
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
    },
  };
  </script>
  