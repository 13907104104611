<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Kegiatan Kelompok</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input-kegiatan-kelompok
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>
            <b-row>
              <b-col cols="4" md="4" lg="4">
                <label for="tanggal">Bulan - Tahun</label>
                <date-picker
                  style="width: 100%"
                  id="tanggal"
                  format="MMMM - YYYY"
                  type="month"
                  v-model="dataFilter.tanggal"
                ></date-picker>
              </b-col>
              <b-col cols="4" md="4" lg="4">
                <label>Kegiatan</label>
                <multiselect
                  id="kd_tkp"
                  v-model="dataFilter.kode_kegiatan"
                  :options="$store.state.data_static.kegiatan"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  placeholder="-- Pilih Kegiatan --"
                  size="sm"
                ></multiselect>
              </b-col>
              <b-col cols="4" md="4" lg="4">
                <label>Kelompok</label>
                <multiselect
                  id="kd_tkp"
                  v-model="dataFilter.kode_kelompok"
                  :options="$store.state.data_static.kelompok"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  placeholder="-- Pilih Kelompok --"
                  size="sm"
                ></multiselect>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="2"
                md="2"
                lg="2"
                offset-lg="10"
                offset-md="10"
                style="padding-top: 20px"
              >
                <b-button
                  style="width: 100%"
                  @click="getDatas()"
                  variant="success"
                  >Cari
                </b-button>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  responsive
                  show-empty
                  small
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <div class="d-flex nowrap">
                      <!-- <b-button
                        class="mr-1"
                        variant="info"
                        v-c-tooltip.hover.click="'Detail Data'"
                        @click="$router.push('./detail_rekam_medis/' + item.item.rm_id)"
                        ><CIcon name="cil-info" /></b-button
                      > -->
                      <b-button
                        class="mr-1"
                        variant="info"
                        v-c-tooltip.hover.click="'Detail Data'"
                        v-b-modal.modal-detail-kegiatan-kelompok
                        @click="is_data = item.item"
                        ><CIcon name="cil-info" /></b-button
                      >
                      <b-button
                        class="mr-1"
                        variant="info"
                        v-c-tooltip.hover.click="'Peserta'"
                        v-b-modal.modal-input-peserta-kegiatan-kelompok
                        @click="is_data = item.item"
                        ><CIcon name="cil-people" /></b-button
                      >
                      <b-button 
                        class="mr-1"
                        variant="danger"
                        v-c-tooltip.hover.click="'Hapus Data'"
                        v-b-modal.modal-delete
                        @click="is_data = item.item"
                        ><CIcon name="cil-trash" /></b-button
                      >
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="5" offset-md="3">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <ModalInputPeserta
      :is_data="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <ModalInput
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <!-- <ModalEdit
      :is_data="is_data"
      :provinsi="provinsi"
      :golongan_darah="golongan_darah"
      :etnis="etnis"
      :pendidikan="pendidikan"
      :pekerjaan="pekerjaan"
      @alertFromChild="triggerAlert($event), getDatas()"
    /> -->
    <ModalDelete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <ModalDetail
      :is_data="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalInputPeserta from "./modalInputPeserta.vue";
import ModalInput from "./modalInput.vue";
// import ModalEdit from "./modalEdit.vue";
import ModalDelete from "./modalDelete.vue";
import ModalDetail from "./modalDetail.vue";
import moment from "moment"
moment.locale("ID")
export default {
  components: {
    ModalInputPeserta,
    ModalInput,
    // ModalEdit,
    ModalDelete,
    ModalDetail,
  },
  name: "master_pasien",
  data() {
    return {
      dataFilter: {
        tanggal:  new Date(this.tanggal_sesi),
        kode_kegiatan: null,
        kode_kelompok: null,
      },
      optionsStatus: [
          {value:"0", text:"Tidak Aktif"},
          {value:"1", text:"Aktif"},
      ],
      showing: false,
      variant: "success",
      msg: "",
      data: "",
      is_data: {},
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "edu_id",
          label: "Edu ID",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_club",
          label: "Kode Club",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_tanggal_pelayanan",
          label: "Tanggal Pelayanan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_kegiatan",
          label: "Kegiatan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_kelompok",
          label: "Kelompok",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "materi",
          label: "Materi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "pembicara",
          label: "Pembicara",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "lokasi",
          label: "Lokasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "keterangan",
          label: "Keterangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_biaya",
          label: "Biaya",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      tableBusy: false,
    };
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
  },
  watch: {
    tanggal_sesi(newVal, oldVal){
      if(newVal != oldVal){
        this.dataFilter.tanggal = new Date(this.tanggal_sesi)
      }
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.reset();
  },
  methods: {
    async getDatas() {
      let vm = this
      vm.tableBusy = true;
      let items = await vm.$axios.post("/kegiatan_kelompok/list", {
        ...vm.dataFilter
      });
      // console.log('items', items)
      vm.items = items.data.status == 200 ? items.data.data.map((x, i) => {
        return {
          ...x,
          no: i + 1,
          nama_tanggal_pelayanan: vm.$moment(x.tanggal_pelayanan).format('LL'),
          nama_biaya: vm.$numberFormat(x.biaya, 'idr'),
        }
      }) : [];
      // console.log(vm.items, 'ini item');
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    async reset(){
      const vm = this
      vm.busy = true
      vm.dataFilter = {
        tanggal:  new Date(this.tanggal_sesi),
        kode_kegiatan: null,
        kode_kelompok: null,
      }
      vm.busy = false
      vm.getDatas()
    },
    triggerAlert(event) {
      let vm = this;
      vm.getDatas
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
