<template>
    <div>
      <b-modal
        id="modal-input-peserta-kegiatan-kelompok"
        size="xl"
        centered
        title="Tambah Peserta Kegiatan Kelompok"
        header-bg-variant="primary"
        header-text-variant="light"
        @show="openModal()"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No BPJS
            </template>
            <b-form-input
              :state="checkIfValid('no_bpjs')"
              type="text"
              v-model="$v.data.no_bpjs.$model"
            ></b-form-input>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button
              variant="success"
              :disabled="busy || !isValid"
              @click="cari()"
              >Cari</b-button
            >
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="busy || !isValid || !pasien.rm_id"
              @click="simpan()"
              >Tambah</b-button
            >
          </div>
          <!-- 0000093115361 -->
          <div v-if="pasien.rm_id" class="card p-3 mt-3">
            <h5>{{pasien.nama_lengkap}}</h5>
            <b-row class="m-0">
              <b-col cols="2">No BPJS</b-col>
              <b-col cols="10">: {{pasien.no_bpjs}}</b-col>
              <b-col cols="2">Jenis Kelamin</b-col>
              <b-col cols="10">: {{pasien.jenis_kelamin == 'P' ? 'Perempuan' : 'Laki - Laki'}}</b-col>
              <b-col cols="2">Tanggal Lahir</b-col>
              <b-col cols="10">: {{$moment(pasien.tanggal_lahir).format('LL')}}</b-col>
              <b-col cols="2">Diabetes</b-col>
              <b-col cols="10">: {{pasien.pst_prb || '-'}}</b-col>
              <b-col cols="2">Hipertensi</b-col>
              <b-col cols="10">: {{pasien.pst_prol || '-'}}</b-col>
            </b-row>
          </div>
          <!-- <pre>{{is_data}}</pre> -->
        </b-form>
        <div class="mt-3">
          <b-table
            :items="items"
            :fields="fields"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(actions)="item">
              <div class="d-flex nowrap">
                <b-button
                  variant="danger"
                  size="sm"
                  class="ml-1"
                  v-c-tooltip.hover.click="'Hapus Peserta'"
                  @click="hapus(item.item)"
                  ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                >
              </div>
            </template>
          </b-table>
        </div>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-input-peserta-kegiatan-kelompok')">
            Batal
          </b-button>
        </template>
      </b-modal>
    </div>
</template>
  
<script>
  import { validationMixin } from "vuelidate";
  // import { required } from "vuelidate/lib/validators";
  export default {
    name: "modalInput",
    props: ['is_data'],
    data() {
      return {
        items: [],
        fields: [
          {
            key: "no",
            label: "No",
            sortDirection: "desc",
            sortable: true,
            class: "table-number text-center",
          },
          {
            key: "no_bpjs",
            label: "No BPJS",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "nama_lengkap",
            label: "Nama Pasien",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "nama_jenis_kelamin",
            label: "Jenis Kelamin",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "nama_tanggal_lahir",
            label: "Tanggal Lahir",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "pst_prb",
            label: "Diabetes",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "pst_prol",
            label: "Hipertensi",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
        ],
        data: {
          no_bpjs: null,
        },
        pasien: {},
        busy: false,
        button: "Simpan",
      };
    },
    computed: {
      tanggal_sesi(){
        return this.$store.state.tanggal_sesi
      },
      formString() {
        return JSON.stringify(this.data, null, 4);
      },
      isValid() {
        return !this.$v.data.$invalid;
      },
      isDirty() {
        return this.$v.data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      data: {
        no_bpjs: {  },
      },
    },
    methods: {
      openModal(){
        const vm = this
        vm.reset()
        vm.getDatas()
      },
      async cari(){
        const vm = this
        vm.busy = true
        try {
          await this.$v.data.$touch();
          if (vm.isValid && vm.isDirty) {
            let res = await vm.$axios.post('/rm/list', {
              ...vm.data,
            })
            vm.pasien = {}
            // console.log('rm/list', res)
            if(res.data.status == 200){
              if(res.data.data && res.data.data[0].no_bpjs == vm.data.no_bpjs){
                vm.pasien = res.data.data[0]
                // vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Membuat Kegiatan Kelompok', showing: true });
                // vm.$bvModal.hide('modal-input-peserta-kegiatan-kelompok')
                // vm.reset()
              }
            }else{
              vm.$emit("alertFromChild", { variant: "danger", msg: res.data.message, showing: true });
            }
          }else{
            vm.$emit("alertFromChild", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
          }
        } catch (error) {
          console.log(error)
          if (error.message) {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
          } else {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
          }
        } finally {
          vm.busy = false
        }
      },
    async getDatas(){
      const vm = this
      vm.busy = true
      try {
        // const items = await vm.$axios.post('/kegiatan_kelompok/list')
        const items = await vm.$axios.post('/peserta_kegiatan_kelompok/list', {kegiatan_kelompok_id: vm.is_data.kegiatan_kelompok_id})
        // console.log('peserta_kegiatan_kelompok/list', items)
        if(items.data.status == 200){
          vm.items = items.data.status == 200 ? items.data.data.map((x, i) => { return {
            ...x,
            no: i + 1,
            nama_jenis_kelamin: vm.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan',
            nama_tanggal_lahir: vm.$moment(vm.tanggal_lahir).format('LL'),
          }}) : []
        }
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
      async simpan(){
        const vm = this
        vm.busy = true
        try {
          await this.$v.data.$touch();
          if (vm.isValid && vm.isDirty) {
            let res = await vm.$axios.post('/peserta_kegiatan_kelompok/register', {
              rm_id: vm.pasien.rm_id,
              kegiatan_kelompok_id: vm.is_data.kegiatan_kelompok_id,
            })
            // console.log('peserta_kegiatan_kelompok/register', res)
            if(res.data.status == 200){
              vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Menambahkan Peserta Kegiatan Kelompok', showing: true });
              vm.reset()
              vm.getDatas()
            }else{
              vm.$emit("alertFromChild", { variant: "danger", msg: res.data.message, showing: true });
            }
          }else{
            vm.$emit("alertFromChild", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
          }
        } catch (error) {
          console.log(error)
          if (error.message) {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
          } else {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
          }
        } finally {
          vm.busy = false
        }
      },
      async hapus(data){
        const vm = this
        vm.busy = true
        try {
          await this.$v.data.$touch();
          let res = await vm.$axios.post('/peserta_kegiatan_kelompok/delete', {id: data.peserta_kegiatan_kelompok_id})
          // console.log('peserta_kegiatan_kelompok/delete', res)
          if(res.data.status == 200){
            vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Menghapus Peserta Kegiatan Kelompok', showing: true });
            vm.reset()
            vm.getDatas()
          }else{
            vm.$emit("alertFromChild", { variant: "danger", msg: res.data.message, showing: true });
          }
        } catch (error) {
          console.log(error)
          if (error.message) {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
          } else {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
          }
        } finally {
          vm.busy = false
        }
      },
      reset() {
        const vm = this
        vm.$v.$reset();
        vm.data = {
          no_bpjs: null,
        };
        vm.pasien = {}
      },
      checkIfValid(fieldName) {
        const field = this.$v.data[fieldName];
        // console.log('fieldName', fieldName)
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
    },
  };
  </script>
  